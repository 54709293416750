import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { DataLoadingStatusEnum } from './enums';

class Gauge extends Component {
  render() {
    if (this.props.dataLoadingStatus !== DataLoadingStatusEnum.READY) {
      return <div>Fetching data from API</div>;
    }

    // Get the latest value from the temp data
    const value = this.props.chartData[this.props.chartData.length - 1][this.props.valueIndex];

    return (
      <div>
        <h3>{this.props.name + ' °C'}</h3>
        <Chart
          height={180}
          chartType="Gauge"
          loader={<div>Loading Gauge</div>}
          data={[
            ['Label', 'Value'],
            ['', value],
          ]}
          options={{
            redFrom: this.props.maxOptimumTemp,
            redTo: 120,
            yellowFrom: this.props.minOptimumTemp,
            yellowTo: this.props.maxOptimumTemp,
            greenFrom: 30,
            greenTo: this.props.minOptimumTemp,
            minorTicks: 5,
            greenColor: '#0000FF',
          }}
        />

        <p>Optimum Temp Range {this.props.minOptimumTemp} - {this.props.maxOptimumTemp}</p>
      </div>
    );
  }
}

Gauge.defaultProps = {
  valueIndex: 1,
  minOptimumTemp: 52,
  maxOptimumTemp: 68,
};

export default Gauge;
