import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { DataLoadingStatusEnum } from './enums';

class TempGraph extends Component {
  render() {
    if (this.props.dataLoadingStatus !== DataLoadingStatusEnum.READY) {
      return <div>Fetching data from API</div>;
    }

    return (
      <div>
        <Chart
          height={400}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={this.props.chartData}
          options={{
            title: 'Temperatures',
            hAxis: {
              title: 'Date',
              format: 'd MMM y',
            },
            vAxis: {
              title: 'Temp °C',
            },
            explorer: {
              actions: ['dragToZoom', 'rightClickToReset'],
            },
            series: [{ color: this.props.seriesColors[0] }, { color: this.props.seriesColors[1] }],
          }}
        />{' '}
      </div>
    );
  }
}

TempGraph.defaultProps = {
  seriesColors: [ 'red', 'magenta' ]
}

export default TempGraph;
