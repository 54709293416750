import { Component } from 'react';
import { DataLoadingStatusEnum } from './enums';

class TankTempData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoadingStatus: DataLoadingStatusEnum.LOADING,
      chartData: [],
    };
  }

  render() {
    return null;
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(previous) {
    if (this.props.from === previous.from) {
      return;
    }

    this.fetchData()
  }

  async fetchData() {
    const response = await fetch(
      `${this.props.apiUrl}/temp/${this.props.unit}?from=${this.props.from}&to=${this.props.to}`
    );
    const json = await response.json();

    const data = [this.props.dataHeaders];
    json.forEach(element => {
      data.push([
        new Date(element['timestamp']),
        element['maintank'],
        element['ringreturn'],
      ]);
    });

    this.setState({
      dataLoadingStatus: DataLoadingStatusEnum.READY,
      chartData: data,
    });

    this.props.dataLoadedCallback(data, DataLoadingStatusEnum.READY);
  }
}

TankTempData.defaultProps = {
  apiUrl: `http://localhost:5000/api/log`,
  unit: '127.0.0.1',
  from: '2019-11-30',
  to: '2019-12-01',
  dataHeaders: ['Time', 'Main Tank', 'Ringmain Return']
};

export default TankTempData;
