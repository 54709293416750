import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { DataLoadingStatusEnum } from './enums';

class MaximumDemandGraph extends Component {
  render() {
    if (this.props.dataLoadingStatus !== DataLoadingStatusEnum.READY) {
      return <div>Fetching data from API</div>;
    }

    return (
      <div>
        <Chart
          height={400}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={this.props.chartData}
          options={{
            title: 'Maximum Demand',
            hAxis: {
              title: 'Date',
              format: 'd MMM y',
            },
            vAxis: {
              title: 'KW',
            },
            explorer: {
              actions: ['dragToZoom', 'rightClickToReset'],
            },
            series: [{ color: 'blue' }],
          }}
        />
      </div>
    );
  }
}

MaximumDemandGraph.defaultProps = {};

export default MaximumDemandGraph;
