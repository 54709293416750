import React, { memo } from 'react';
import './logo.css';

function Logo() {
  return (
   <img src="images/faviconRed.png" />
  );
}

export default memo(Logo);
