import { Component } from 'react';
import { DataLoadingStatusEnum } from './enums';

class CountData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoadingStatus: DataLoadingStatusEnum.LOADING,
      chartData: [],
    };
  }

  render() {
    return null;
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(previous) {
    if (this.props.from === previous.from) {
      return;
    }

    this.fetchData()
  }

  async fetchData() {
    const response = await fetch(
      `${this.props.apiUrl}/count/${this.props.unit}?from=${this.props.from}&to=${this.props.to}`
    );
    const json = await response.json();

    const data = [this.props.dataHeaders];
    for (let i = 1; i < json.length; i++) {
      const timestamp = new Date(json[i]['timestamp']);
      const prevTimestamp = new Date(json[i - 1]['timestamp']);

      const diff = Math.abs(timestamp - prevTimestamp);
      const minutes = Math.round(diff / 1000 / 60);
      const interval = Math.round(minutes / this.props.interval);

      const currentVal = json[i][this.props.dataKey];
      const prevVal = json[i - 1][this.props.dataKey];
      let actualVal = (currentVal - prevVal) / interval;
      data.push([timestamp, actualVal]);
    }

    this.setState({
      dataLoadingStatus: DataLoadingStatusEnum.READY,
      chartData: data,
    });

    this.props.dataLoadedCallback(data, DataLoadingStatusEnum.READY);
  }
}

CountData.defaultProps = {
  apiUrl: `http://localhost:5000/api/log`,
  unit: '127.0.0.1',
  from: '2019-11-30',
  to: '2019-12-01',
  dataHeaders: ['Time', 'KW'],
  dataKey: 'count2',
  interval: 30
};

export default CountData;
